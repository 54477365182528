<template>
    <div class="mainboxbodu3">

   
        <div class="H-bar-mobile">
            <i class="material-icons" style="cursor:pointer;"  @click="$root.modal_open =  false" >arrow_back</i>
            <a class="jweiofwe">Verify 2  <i class="material-icons">verified_user</i></a>  
            <i class="material-icons" style="color:#111b11">edit</i>
        </div>


        <template  v-if="$root.user.kyc_lvl >= 3" ><a class="no_smalc">      You must provide a proof of address.</a> <div class="hboxee">Already completed and approved. </div></template>
        <template v-else>
     
<template  v-if="!previewImage" >
    <a class="no_smalc">    You must provide a proof of address. This can include but is not limited to:
        <br><br>
Utility bill (electricity, water, gas, etc.) issued within the last three months <br>
Bank or credit card statement issued within the last three months <br>
Rental or lease agreement <br>
Property tax bill or receipt <br>
Official government letter or document (e.g. tax assessment, voter registration, etc.) <br>
 </a>       












  
    </template>
        <input id="filerr" style="display:none;"  @input="pickFile" type="file" v-if="!previewImage" name="file" ref="fileInput1"/>
<label for="filerr"  class="fancy_file" v-if="!previewImage" >Select Document</label>

</template>


  







   
        <div style="display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;">
            <template    v-if="previewImage"> 
<div class="cropD_ixe">     <br>
    Adjust:   <br></div>
</template>
    <cropper v-if="previewImage" 
		:src="previewImage"
        ref="cropper"
 
		@change="change"
  

     
	/> 
<!-- {{ image.image.src }} -->
<button style="margin-top:20px;" @click="reset()" class="reset-btn" v-if="previewImage" >Reset Image<i class="material-icons">delete_forever</i></button>

  </div>



  
<div> <br  v-if="previewImage" >
  <input   v-model="agreed" v-if="previewImage" type="checkbox" id="john" value="John" >
    <label  v-if="previewImage" class="check_font" for="john">To the best of my knowledge, the information I am conveying is true and entirely accurate.</label><br  v-if="previewImage" ><br  v-if="previewImage" >
    <input   v-model="agreed2" v-if="previewImage" type="checkbox" id="john2" value="John2" >
    <label  v-if="previewImage" class="check_font" for="john2">You aknowlage what we do with your information.</label>

    <a  v-if="!previewImage">It is our policy to keep your info and information as private as possible. See why it is necessary <span style="color: #66d345;cursor:pointer;" @click="$router.push('/faq')">here</span>.</a><br><br>
                            <a  v-if="!previewImage" style="color:grey;font-style: italic;font-size:12px;">You will need to complete this inorder to with withdraw more than 1,000 total gems.</a>
                            <button style="margin-top:-10px;" class="sum_Pbvikre" :class="[(agreed && agreed2) ? '' : 'wityhfdraw']"  v-if="previewImage" @click="uploadImgToServer()">Sumbit KYC 3</button>
                            <img v-if="loading" src="./../assets/loading-gif.gif" style="
    width: 32px;
    margin-top: 10px;
">


<template  v-if="previewImage">
    <br> <br>
   <a style="    font-size: 13px;
    color: #787878;
    font-style: italic;"> We want to keep your information private and will not do anything with it apart from legal AML causeses</a>

</template>

</div>  
 

   
<br>

    </div>
</template>

<script>

import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

import axios from 'axios'

export default {
    name: 'ResizeKYC3Component',
	components: {
		Cropper,
	},
	data() {
		return {
            previewImage: null,
            blob: [],
            image: [],
            auctalBlob: [],
            loading: false,
            agreed: false,
            agreed2: false,
		};
	},
	methods: {
        reset() {
			this.previewImage = null;
		},
		change({ coordinates, canvas }) {
			console.log(coordinates, canvas);
            // this.image = this.$refs.cropper.getResult()

            canvas.toBlob((blob) => {
                this.auctalBlob = blob
                var imgb4  = URL.createObjectURL(blob);
                this.image = imgb4
            })

    
   
		},
        aspectRatios() {
			return {
				minimum: 1 || 1,
				maximum: 1 || 1,
			};
		},
        selectImage () {
          this.$refs.fileInput1.click()
      },
      pickFile () {

        
        let input = this.$refs.fileInput1
        let file = input.files
       

        if (input.files[0]) {




            if (!(input.files[0].type == 'image/png' || input.files[0].type == 'image/jpeg')) {
    this.$toast.show('Sorry we only currently accept images files. (png/jpg)', { position: "bottom", duration: 2500 });
} else {


    if (file && file[0]) {
    let reader = new FileReader
    reader.onload = e => {
        this.previewImage = e.target.result
    }
    reader.readAsDataURL(file[0])
    this.$emit('input', file[0])
}


}
}



      },
      uploadImgToServer() {
        const form_data = new FormData();
        
        form_data.append('image', this.auctalBlob)
        form_data.append('token', this.$cookies.get('token'))

                    console.log(form_data)
                    this.loading = true

                    axios.post(`${this.$root.API_PREFIX}/api/update_kyc3`, form_data).then(response => {
                if (response.data.message) {
                    this.$toast.show(response.data.message, { position: "bottom", duration: 2500 });
                   
                    this.$root.modal_open = false
                    this.$root.user.kyc_lvl = 3
                    this.$root.user.info_msg_kyc = ''


                }
            })
            },
	},
};
</script>


<style scoped>

.sum_Pbvikre {
    color: #42da3b;
    font-size: 16px;
    background: #268126;
    border: 1px dashed #242424;
    cursor: pointer;
    border-radius: 10px;
    margin: 0px;
    margin-top: 20px;
    transition: 0.5s;
}
.sumbit {
    color: #42DA3D;
    font-size: 13px;
    background: #053b24;
    border: 1px dashed #000000;
    cursor: pointer;
    border-radius: 10px;
    height: 32px;
    margin: 0px;
    width: 274px;
}

.reset-btn {
    font-family: 'Gilroy';
    color: #fdfdfd;
    font-size: 12px;
    background: #000000;
    border: 1px dashed #949494;
    cursor: pointer;
    border-radius: 10px;
    padding: 10px;

    display: flex;
    justify-content: center;
    margin:0px;
    align-items: center;

}

.hboxee {
    padding: 20px 10px;
    background: #000000;
    width: 100%;
    margin: 10px 0px;
    border: 1px solid #7a7a7a;
    color: #f3b63e;
    border-radius: 10px;
}


.vue-advanced-cropper__background {
    background: #111b11;
}

.fancy_file {
    font-family: 'Gilroy';
    color: #f3c808;
    font-size: 12px;
    background: #ff900026;
    border: 1px dashed #242424;
    cursor: pointer;
    border-radius: 10px;
    padding: 10px;
    margin: 10px 0px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no_smalc {
    font-size: 16px;
    margin: 0;
}

.vue-advanced-cropper {
    width: 90vw;
    max-width: 400px;
    transition: opacity 300ms ease 0s;
    pointer-events: all;
    border: 1px solid rgba(206, 206, 206, 0.425);
}

.jweiofwe {
        font-family: 'Gilroy', cursive;
    font-weight: 100;
    font-style: normal;
    letter-spacing: 0px;
    font-size: 24px;
    color: #ffffff;
    }

@media only screen and (max-width: 600px) {

    .vue-advanced-cropper {

    max-height: 300px;


}
    .no_smal {
        display: none;
    }

.reset-btn {
    margin-top: 20px;
    margin:0px;
}


.H-bar-mobile  {
width: 100%;
height: 50px;
align-items: center;
justify-content: space-between;
background-color: #111b11;
flex-shrink: 0;
box-sizing: border-box;
touch-action: none;
padding: 0px 20px;
color: white !important;
box-shadow: unset;
}
}


.wityhfdraw {
    filter: grayscale(1);
    cursor:not-allowed;
}
.vue-advanced-cropper__background {
    background: #111b11;
}

.mainboxbodu3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}
.imagePreviewWrapper {
    width: 250px;
    height: 250px;
    display: block;
    cursor: pointer;
    margin: 0 auto 30px;
    background-size: cover;
    background-position: center center;
}
.cropD_ixe {
    margin-bottom: 10px;
    flex-direction: column;
    align-items: flex-start;
    font-family: 'Gilroy', cursive;
    font-size: 16px;
    color: white;
    display: flex;

}
</style>