<template>
  <a>
    <a style="color: aquamarine;" v-if="nick"> {{ nick }}</a> <a v-else>{{  username }}</a>
    </a>
  </template>
  
  <script>
  import axios from 'axios'
  
  export default {
      name: 'NicknameComponent',
    data() {
  
      return {
  
    
          nick: null,

  
  
      }
  
    },
      
      props: {
          username: String,
    
  
      },  
  mounted() {
  
  
  
              axios.post(`${this.$root.API_PREFIX}/api/user_nickname`,  {
                  username: this.username
              })
              .then(response => {

                console.log(response.data )

                  if (response.data == null) {
                      console.log("cant log in no response")
                     
                  } else {
                    this.nick = response.data

  
                    
              }
          })
      }
  }
  
     
  
  
  </script>
  
  <style scoped> 
  
  </style>
  