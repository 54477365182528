<template>
    <div class="home">    
        <div class="h-bar-0-mobile">
            <i  @click="back()" class="material-icons">arrow_back</i>
            <h1 class="new_top_head">{{username}}</h1>
            <i  @click="copy_userid()"  v-clipboard:copy="userdata.uuid" v-clipboard:error="onError"  class="material-icons spcur">more</i>
        </div> 
        <div class="title_name_level">



            <div class="t3" v-if="!user">
                <a class="bio_Desc" style="font-size:38px; color:sliver;">{{username}} doesn't exist</a>  <br>
                <a class="bio_Desc" style="font-size:18px; color:sliver;">They may have deleted there account. If you think something is wrong contact please support.</a>  
            </div>
            <div class="flexy_gholder"  v-if="user">



                    <a v-if="username == $root.user.name" style="color:white; font-family: 'Gilroy';">You are viewing your own account, click below to view your profile.</a>
                    <br v-if="username == $root.user.name">    <br v-if="username == $root.user.name">    
                  <button class="clicker" v-if="username == $root.user.name" @click="$router.push('/profile')">Go to <i style="padding: 0px 4px;"> Your </i> profile </button>

                    <br v-if="username == $root.user.name">    



            <img class="img_dp_2000"  :src="`${this.$root.API_PREFIX}/api/DisplayPicture?name=${userdata.username}`"/>

                <div class="holderzer" ><a  class="nickanme">{{username}}</a>


                    <a v-if="userdata.label_no" :style="getLabelStyle(userdata.label_no)">
      {{ getLabelName(userdata.label_no) }}
    </a>

                    <LevelBadgeComponent  v-if="userdata.lifetime_wagered != null" :total_wager="userdata.lifetime_wagered" /></div>

        <a   v-if="userdata.nickname"  style="color: #ffffff91;
    font-size: initial; font-size: 20px;
    font-variant: all-small-caps;" class="nickanme"> nickname:   <a v-if="userdata.nickname" style="margin-left: 10px;color: #00ffa9;
    font-size: initial; font-size: 20px;
    font-variant: all-small-caps;" class="nickanme">{{userdata.nickname}} </a> </a>    
 

                <a class="bio_Desc" style="font-size:16px;color: #bc0af7;" v-if="userdata.handle">@{{userdata.handle}}</a><br>
                <a class="bio_Desc">Total games played: {{userdata.total_joined + userdata.total_created}} </a>
                <a class="bio_Desc" style="font-size:23px; color:rgb(255, 255, 255);"><br>{{userdata.bio}}</a>

       
                <a class="bio_Desc" style="font-size:20px; color:grey;" v-if="!userdata.bio">This user has no bio set</a>
                <br>

                <a class="bio_Desc" style="    color: grey;
    font-style: italic;
">{{userdata.uuid}}<i  @click="copy_userid()" v-clipboard:copy="userdata.uuid" v-clipboard:error="onError" class="material-icons spcur2">more</i></a><br>
         <button v-if="username != $root.user.name" class="tip_btn" @click="$root.modal_open = 'tip_box', $root.tipName = userdata.username">SEND TIP</button>
            </div>

            <div style="width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    align-items: center;" v-if="!loaded">

    <img src="./../assets/loading-gif.gif" style="
    width: 34px;
">

</div>


            <div v-if="$root.user.admin == true && admin_data" class="a_box" style="margin-bottom: 20px;">

                <i style="color:white;cursor: pointer;" @click="closeAdminPanel()" class="material-icons">close</i>
                <a style="    color: coral;
    font-size: 26px;
    background-color: brown;"> Admin  Panel:</a><br><br>
    <template v-if="admin_data" >

        <!-- Green Orb for Online -->
        <div  v-if="admin_data.isOnline" class="status-orb online"></div>

<!-- Red Orb for Offline -->
<div v-else class="status-orb offline"></div>
<br>
</template>
<a v-if="admin_data" style="color:rgb(34, 132, 189);">$ {{admin_data.bal}}</a><br>
<a v-if="admin_data" style="color:white;">Lifetime deposited: {{admin_data.totalDeposited}}</a><br>
<a v-if="admin_data" style="color:white;">Lifetime withdrawalled: {{admin_data.totalWithdrawn}}</a><br>
<a v-if="admin_data" style="color:white;">Referrals: {{admin_data.referrals}}</a><br>
<a v-if="admin_data" style="color:white;">Referral code: {{admin_data.referralCode}}</a><br>
<br>



<button class="b_btn" @click="resetProfilePicture">Remove Profile Picture</button>
<button class="b_btn" @click="removeBio()">Remove Bio</button>
<button class="b_btn" @click="removeNickname()">Remove Nickname</button>
<button class="b_btn" @click="lockWithdrawal()">Toggle Withdrawal</button>

<h4 style="color: white;">Set Profile Picture</h4>
<input   type="file" name="file" id="file" v-if="!previewImage" ref="fileInput" style="color: white;" @input="pickFile"/>
<img height="100px" width="100px"	v-if="previewImage" :src="previewImage" >
<button class="sumbit"  v-if="previewImage" @click="uploadImgToServer()">Set Image</button>

                </div>
                <br><br>
        </div>
    </div>
</template>

<script>
import LevelBadgeComponent from '../components/LevelBadgeComponent.vue'
import axios from 'axios'
export default {
  name: 'UserProfileComponent',
    components: {  LevelBadgeComponent },   
   data() {
        return {
        
            inspected_cf: [],
            userdata: [],
            user: true,
            xp: 0,
            loaded: false,
            admin_data: null,
            blob: [],
            auctalBlob: '',
            image: [],
            previewImage: ''
        }
    },  props: {
          username: String,
    
  
      },   
      
      
    
    methods: {
      pickFile () {
        let input = this.$refs.fileInput
        let file = input.files
        if (file && file[0]) {
          let reader = new FileReader
          reader.onload = e => {
            this.previewImage = e.target.result
          }
          reader.readAsDataURL(file[0])
          this.$emit('input', file[0])
        }
      },
     
    // Function to determine the label name based on label number
    getLabelName(labelNo) {
      switch (labelNo) {
        case 1: return "YouTube";
        case 2: return "Kick Streamer";
        case 3: return "Admin";
        case 4: return "Mod";
        case 5: return "Dev";
        case 6: return "Streamer";
        case 7: return "Moderator";
        case 8: return "Contributor";
        case 9: return "VIP";
        case 10: return "Support";
        case 11: return "Support+";
        default: return ""; // Default label
      }
    },
    closeAdminPanel() {
    this.admin_data = null;
  },
  uploadImgToServer() {
        const form_data = new FormData();
   
 this.previewImage

 const base64Image =  this.previewImage.split(';base64,').pop();

// Decode base64 data
const decodedImageData = atob(base64Image);

// Convert decoded data into a blob
const byteArray = new Uint8Array(decodedImageData.length);
for (let i = 0; i < decodedImageData.length; i++) {
    byteArray[i] = decodedImageData.charCodeAt(i);
}

const blob = new Blob([byteArray], { type: 'image/jpeg' });

console.log(this.userdata)
        // form_data.append('image', blob)
        form_data.append('token', this.$cookies.get('token'))
        form_data.append('username', this.userdata.username)
        form_data.append('image',blob, 'image.png')

console.log(form_data)

                    this.loading = true

                    axios.post(`${this.$root.API_PREFIX}/api/uploadDisplayPicture_admin`, form_data).then(response => {
                if (response.data) {
                    // this.file = ""
                    // this.text = "image has been added to server " + response.data.img
                    // this.$root.user.profile_picture = response.data.img
         console.log(response.data) 
           setTimeout(function () { window.location.reload() }.bind(this), 200)

                 
                   



                }
            })
            },
    // Function to determine the inline style for label based on label number
    getLabelStyle(labelNo) {
      switch (labelNo) {
        case 1:
        return {
            color: 'white',
            backgroundColor: '#f52020',
            fontSize: '14px',
            border: '1px solid #000000',
            padding: '0px 20px',
     
            marginLeft: '10px',
            borderRadius: '10px'
          };
        case 2:
          return {
            color: 'black',
            backgroundColor: '#a7a7a7',
            fontSize: '18px',
            border: '1px solid #000000',
            padding: '0px 20px',
    
            marginLeft: '10px',
            borderRadius: '10px'
          };
        case 3:
        return {
            color: 'black',
            backgroundColor: '#f4f520',
            fontSize: '18px',
            border: '1px solid #000000',
            padding: '0px 20px',

            marginLeft: '10px',
            borderRadius: '10px'
          };
        case 4:
        return {
            color: 'black',
            backgroundColor: '#6bd11b',
            fontSize: '18px',
            border: '1px solid #000000',
            padding: '0px 20px',
       
            marginLeft: '10px',
            borderRadius: '10px'
          };
        case 5:
          return {
            color: 'black',
            backgroundColor: '#f59020',
            fontSize: '18px',
            border: '1px solid #000000',
            padding: '0px 20px',
         
            marginLeft: '10px',
            borderRadius: '10px'
          };
        case 6:
          return {
            color: 'black',
            backgroundColor: '#ffbf00',
            fontSize: '18px',
            border: '1px solid #000000',
            padding: '0px 20px',
   
            marginLeft: '10px',
            borderRadius: '10px'
          };
        case 7:
          return {
            color: 'white',
            backgroundColor: '#800080',
            fontSize: '30px',
            fontVariant: 'all-small-caps'
          };
        case 8:
          return {
            color: 'black',
            backgroundColor: '#00bfff',
            fontSize: '16px',
            border: '1px solid #000000',
            padding: '0px 20px',
  
            marginLeft: '10px',
            borderRadius: '10px'
          };
        case 9:
          return {
            color: 'black',
            backgroundColor: '#ffc10c',
            fontSize: '16px',
            border: '1px solid #000000',
            padding: '0px 20px',
            fontVariant: 'all-small-caps',
            marginLeft: '10px',
            borderRadius: '10px'
          };
        case 10:
          return {
            color: 'black',
            backgroundColor: '#1c58ff',
            fontSize: '16px',
            border: '1px solid #000000',
            padding: '0px 20px',
    
            marginLeft: '10px',
            borderRadius: '10px'
          };
          case 11:
          return {
            color: 'white',
            backgroundColor: '#1c58ff',
            fontSize: '18px',
            border: '1px solid #000000',
            padding: '0px 20px',
         
            marginLeft: '10px',
            borderRadius: '10px'
          };
        default: return {}; // Default style
      }
    },
 


        resetProfilePicture() {
      // Make the API request to reset the profile picture
      axios.post(`${this.$root.API_PREFIX}/api/reset_profile_picture`,  {
        token: this.$cookies.get('token'),
        tip_to_username: this.$root.tipName
    })
        .then(response => {
          // Handle the API response
          console.log(response.data.message);
          // Perform any necessary actions after resetting the profile picture

          this.$toast.show(response.data.message, {position: "bottom", duration: 1500});
        })
        .catch(error => {
          // Handle errors that occurred during the API request
          console.error(error);
        });
    },

    removeBio() {
  // Make the API request to remove the bio
  axios.post(`${this.$root.API_PREFIX}/api/remove_bio`, {
    token: this.$cookies.get('token'),
    username: this.username
  })
    .then(response => {
      // Handle the API response
      console.log(response.data.message);
      // Perform any necessary actions after removing the bio

      this.$toast.show(response.data.message, {position: "bottom", duration: 1500});
    })
    .catch(error => {
      // Handle errors that occurred during the API request
      console.error(error);
    });
},

removeNickname() {
  // Make the API request to remove the nickname
  axios.post(`${this.$root.API_PREFIX}/api/remove_nickname`, {
    token: this.$cookies.get('token'),
    username: this.username
  })
    .then(response => {
      // Handle the API response
      console.log(response.data.message);
      // Perform any necessary actions after removing the nickname

      this.$toast.show(response.data.message, {position: "bottom", duration: 1500});
    })
    .catch(error => {
      // Handle errors that occurred during the API request
      console.error(error);
    });
},

lockWithdrawal() {
  // Make the API request to lock withdrawal
  axios.post(`${this.$root.API_PREFIX}/api/lock_withdrawal`, {
    token: this.$cookies.get('token'),
    username: this.username
  })
    .then(response => {
      // Handle the API response
      console.log(response.data.message);
      // Perform any necessary actions after locking withdrawal

      this.$toast.show(response.data.message, {position: "bottom", duration: 1500});
    })
    .catch(error => {
      // Handle errors that occurred during the API request
      console.error(error);
    });
},
reloadUserData() {
      // Fetch admin info if the user is admin
      const fetchAdminInfo = () => {
        axios.post(`${this.$root.API_PREFIX}/api/user_info_admin`, {
          token: this.$cookies.get('token'),
          username: this.username,
        })
        .then(response => {
          const adminInfo = response.data;
          console.log(response.data);
          this.admin_data = adminInfo;
        })
        .catch(error => {
          console.error(error);
        });
      };

      if (this.$root.user.admin) {
        fetchAdminInfo();
      }

      // Fetch public user data
      axios.post(`${this.$root.API_PREFIX}/api/user_public`,  {
        username: this.username,
        requester_name: this.$root.user.name
      }).then(response => {
        this.loaded = true;
        if (response.data[0] === undefined) {
          this.user = false;
        } else {
          this.userdata = response.data[0];
          this.xp = response.data[0].lifetime_wagered * 150;
          var level_not_round = (0.04 * Math.sqrt(this.xp * 50)).toFixed(2);
          this.level = Math.trunc(level_not_round);
          this.percentage = Math.abs(this.level - level_not_round);
        }

        if (!this.userdata) {
          this.$router.push({ path: '/coinflip' });
        }
      }).catch(error => {
        console.error(error);
      });
    },

    copy_userid() {
            this.$toast.show(`Copied Users Account ID to Clipboard`, {position: "bottom", duration: 1500});
        },
back() {
    this.$root.viewUser = '' 
},watch: {
    // Watch the username prop for changes
    username: {
      immediate: true,  // Call immediately on component load
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.reloadUserData();  // Trigger the data reload only on change
        }
      },
    }
  },
    },



     mounted () {
      this.reloadUserData();



    
  }
        
    
}
</script>

<style scoped>
.tip_btn {
  color: #ffffff;  background: #111b11;
    border: 1px dashed #777777;
    cursor: pointer;
    border-radius: 10px;
    height: 32px;
    margin: 0px;
    padding: 5px;
    margin-bottom: 20px;
}

.status-orb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin: 4px;
  display: inline-block;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}

.online {
  background-color: green;
  animation: pulse-green 1s infinite;
}

.offline {
  background-color: red;
  animation: pulse-red 1s infinite;
}

/* Animation for the glowing effect */
@keyframes pulse-green {
  0% {
    box-shadow: 0 0 6px rgba(0, 128, 0, 0.8);
  }
  50% {
    box-shadow: 0 0 10px rgba(0, 128, 0, 0.8);
  }
  100% {
    box-shadow: 0 0 6px rgba(0, 128, 0, 0.8);
  }
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 6px rgba(255, 0, 0, 0.8);
  }
  50% {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.8);
  }
  100% {
    box-shadow: 0 0 6px rgba(255, 0, 0, 0.8);
  }
}


.b_btn {
    background: brown;
    color: antiquewhite;
    border: 2px dashed black;
    cursor: pointer;
}
    .holderzer {
        display: flex;
    align-items: center;
    }
    .base {
        margin: 0px;
    padding: 0px 5px;
    border: 1px;
    margin-left: 15px;
    }
.flexy_gholder {
    margin-top: 20px;
    width: 100%;

}

.a_box {
padding: 10px;
    background: #000000;
    border: 1px solid white;
    outline: 1px solid black;
}

.img_dp_2000 {
    height: 110px;
    width: 110px;
    background: #2c2c2c;
    border: 1px solid white;
    outline: 1px solid black;
}
.new_top_head {
        font-family: 'Gilroy', cursive;
    font-weight: 100;
    font-style: normal;
    letter-spacing: 0px;
    font-size: 24px;
    color: #ffffff;
    }
.h-bar-0-mobile  {
    display: none;
}
@media only screen and (max-width: 600px) {
    .spcur2 {
        display: none;
    }
    .h-bar-0-mobile  {
        height: 50px;
        align-items: center;
        justify-content: space-between;
        background-color: #111b11;
        flex-shrink: 0;
        box-sizing: border-box;
        touch-action: none;
        padding: 0px 20px;
        color: white !important;
        box-shadow: unset;
        display: flex;
        position: fixed;
        top: 0;
        width: 100%;
        left: 0;
    }
}
.towardsbox {
        display: flex;
    align-items: center;
    justify-content: center;
    background: #313131;
    padding: 20px;
    margin: 20px 0px;
    font-family: 'Gilroy', cursive;
    color: #ffffff;
}
.spcur2 {
    color:white;
    cursor: pointer;
    font-size: inherit;
    margin-left: 20px;
}
.percentcss {
width: 100%;
    padding: 24px;
}
.t3 {
    background: #000000;
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    border: 1px solid;
}
.title_name_level {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
.tip {
    color: aliceblue;
    font-family: monospace;
    width: 200px;
    background: #883267;
    padding: 19px 0px;
    text-align: center;
    margin-top: 20px;
    border: 1px solid;
    border-radius: 11px;
}
.home {
    max-width: 1280px;
    margin: auto;
    width: 80%;
    margin-bottom: 50px;
}
.title_main2 {
    font-family: 'Gilroy', cursive;
    font-size: 64px;
    color: #3099cc;
    font-weight: 100;
    margin: 0px;
    display: flex;
}
.clicker {
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 13px;
    margin: 0px;
    background: #cecece00;
    border: 1px dashed #999999;
    cursor: pointer;
    border-radius: 10px;
}
.nickanme {
    font-family: 'Gilroy', cursive;
    font-size: 24px;
    color: #ffffff;
    font-weight: 100;
    margin: 0px;
    display: flex;
}
.bio_Desc {
    font-family: 'Gilroy', cursive;
    font-size: 12px;
    color: #ffffff;
    font-weight: 100;
    margin: 0px;
    display: flex;
}
.title_maine {
    display: flex;
    font-size: 25px;
    font-family: monospace;
    color: #f1f1f1;
    font-style: oblique;
    text-decoration: underline;
    margin-top: 20px;
}
.desc_main {
    color: aliceblue;
    font-family: monospace;
    width: 50%;
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
}
.ewq_bio_box {
    background: #646464;
    width: 100%;
    border: 1px solid #323f88;
}
.biox_text {
    font-family: 'Courier New', Courier, monospace;
}
</style>