<template>
    <div class="loader">
      <br />
      <a class="tittle">Maintenance <i style="color:grey;cursor: help;" v-tooltip="'ETA: 30MIN'"  class="material-icons">error_outline</i></a>
      <br />
      <img class="robot" src="../assets/robot.png">
      <br />
      <a class="tittle3">CryptoFlip.GG is currently down for maintenance, we'll be right back!</a>
      <br />
      <button @click="this.$root.server_down = false" v-if="this.$root.user.admin == 1" class="maintenance-btn">Continue as admin...</button> <button @click="maintenanceOff" v-if="this.$root.user.admin == 1" class="maintenance-btn" style="background-color: rgb(0, 105, 70);">Turn off maintenance</button>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  
  export default {
    name: "ServerDownComponent",
    props: ["server_down"],
  
    methods: {
      maintenanceOff() {
        this.$toast.show("Turning maintenance off", { position: "bottom", duration: 1500 });
  
        axios.post(`${this.$root.API_PREFIX}/api/maintenance_off`, {
          token: this.$cookies.get('token'),
        }).then(response => {
          console.log(response.data);
        });
      },
    },
  };
  </script>
  
  <style>
    .ss {
        width: 70px;
    }
    .maintenance-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4bbb17;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.white {
    filter: invert(1);
}
.robot {
    width: 300px;
    border: 2px dashed #969696;
}
.tittle {
    color: #e16aff;
    margin: 0px;
    font-size: 32px;
    font-family: monospace;
}
.tittle3 {
    color: rgb(75, 189, 23);
    color: #969696;
    margin: 0px;
    font-size: 18px;
    padding: 20px;
}

  .loader {
    background-color: #111b11;
    bottom: 0;
    color: white;
    font-size: 32px;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;flex-direction: column;
  }
  
  .fadeout {
    animation: fadeout 3s forwards;
  }

  @keyframes fadeout {
  0%   {opacity: 1;}
  25%  {opacity: 1;}
  50%  {opacity: 1;}
  75%  {opacity: 1;}
  100% {opacity: 0;
      visibility: hidden;}
}
  
  
  </style>
  