<template>
  <div v-if="labelNo" :style="getLabelStyle(labelNo)" class="label-component">
    {{ getLabelName(labelNo) }}
  </div>
</template>

<script>
export default {
  props: {
    labelNo: {
      type: Number,
      required: true
    }
  },
  methods: {
    getLabelName(labelNo) {
      switch (labelNo) {
        case 1: return "YouTube";
        case 2: return "Kick Streamer";
        case 3: return "Admin";
        case 4: return "Mod";
        case 5: return "Dev";
        case 6: return "Streamer";
        case 7: return "Moderator";
        case 8: return "Contributor";
        case 9: return "VIP";
        case 10: return "Support";
        case 11: return "Support+";
        default: return "Unknown"; 
      }
    },
    getLabelStyle(labelNo) {
      switch (labelNo) {
        case 1:
        return {
            color: 'white',
            backgroundColor: '#f52020',

          };
        case 2:
          return {
            color: 'black',
            backgroundColor: '#a7a7a7',

          };
        case 3:
        return {
            color: 'black',
            backgroundColor: '#f4f520',
   
          };
        case 4:
        return {
            color: 'black',
            backgroundColor: '#6bd11b',

          };
        case 5:
          return {
            color: 'black',
            backgroundColor: '#f59020',
    
          };
        case 6:
          return {
            color: 'black',
            backgroundColor: '#ffbf00',
     
          };
        case 7:
          return {
            color: 'white',
            backgroundColor: '#800080',

          };
        case 8:
          return {
            color: 'black',
            backgroundColor: '#00bfff',
 
          };
        case 9:
          return {
            color: 'black',
            backgroundColor: '#ffc10c',
    

          };
        case 10:
          return {
            color: 'black',
            backgroundColor: '#1c58ff',
 
          };
          case 11:
          return {
            color: 'white',
            backgroundColor: '#1c58ff',

          };
        default: return {}; // Default style
      }
    },
  }
};
</script>

<style scoped>
.label-component {
  display: inline-block;          
    font-Size: 14px;
            border: 1px solid #000000;
            padding: 0px 10px;
    
            margin-Left: 2px;
            border-Radius: 10px;
}
</style>