<template>
  <div class="chat" style="z-index: 1000">
    <div class="chat-messages" id="scrollerrr_chats">
      <!-- Display chat messages here -->
      <a
        v-if="$root.messages == '' || !$root.messages"
        style="
          display: flex;
          color: #b7b7b7;
          justify-content: center;
          align-items: center;
          margin: 20px;
        "
        >No new messages</a
      >
      <br />
      <div
        v-for="(message, index) in $root.messages"
        :key="index"
        class="chat-message"
        ref="chatMessages"
      >
        <img
          @click="$root.viewUserProfile(message.username)"
          style="cursor: pointer"
          :src="`${this.$root.API_PREFIX}/api/DisplayPicture?name=${message.username}`"
          alt="User Profile"
          class="profile-picture"
        />
        <div class="message-content">
          <template v-if="message.label_no">
            <LabelComponent :labelNo="message.label_no" />
          </template>
          <span
            class="username inlineMSG"
            @click="$root.viewUserProfile(message.username)"
            style="color: white; cursor: pointer"
            >{{ message.username }}</span
          >

          <NewLevelBadgeComponent
            style="width: 10px"
            :username="message.username"
          /><br />
          <p
            style="
              color: rgb(255, 255, 255);
              font-size: 12px;
              color: #ffffff;
              text-shadow: 0px 0px 10px black;
              overflow: hidden;

              font-family: 'Gilroy';
              overflow-wrap: break-word;
              display: inline-flex;
            "
          >
            {{ message.text }}
          </p>
        </div>
      </div>
    </div>
    <div class="btn_holder_mbd">
      <div class="chat-input">
        <!-- Input field for typing messages -->
        <input
          v-model="newMessage"
          @keyup.enter="sendMessage"
          class="inputerrx"
          placeholder="Type a message..."
        />
        <button style="height: 55px" @click="sendMessage">
          <i class="material-icons">send</i>
        </button>
      </div>
      <div
        class="chat-input"
        style="display: flex; justify-content: center; align-items: center"
      >
        <a
          class="chatboxe"
          @click="$root.modal_open = 'rules'"
          style="
            color: rgb(202, 202, 202);
            cursor: pointer;
            font-size: 12px;
            margin-bottom: 10px;
            text-decoration: underline;
          "
          >Community Chat Guidelines</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import LabelComponent from "@/components/LabelComponent.vue";
import NewLevelBadgeComponent from "@/components/NewLevelBadgeComponent.vue";
export default {
  data() {
    return {
      chatMessages: [],
      messages: [
        {
          text: "Hello, how are you?",

          username: "321e",
        },
        {
          text: "I am doing well, thanks!",

          username: "ewqe32",
        },
        // Add more message objects here
      ],
      newMessage: "",
      onlineUsers: 10, // Set this number according to your implementation
    };
  },
  components: { LabelComponent, NewLevelBadgeComponent },
  sockets: {
    add_message: function () {
     
      const element = document.getElementById("scrollerrr_chats");


      if (element) {
        setTimeout(
          function () {
            element.scrollBy(0, element.scrollHeight);
          }.bind(this),
          10
        );
      }
    },
  },
  mounted() {
    const element = document.getElementById("scrollerrr_chats");
    // this.scrollChatToBottom()
    if (element) {
      setTimeout(
        function () {
          element.scrollBy(0, element.scrollHeight);
        }.bind(this),
        110
      );
    }
  },
  methods: {
    scrollChatToBottom() {
      // Scroll to the bottom of the chat messages
      setTimeout(() => {
        const chatMessages = this.$refs.chatMessages;
        if (chatMessages) {
          chatMessages.scrollTop = chatMessages.scrollHeight;
        }
      }, 0);
    },

    sendMessage() {
      // Check if the user is logged in
      if (!this.$root.logged_in) {
        this.$toast.show(`You must be logged in to send a message`, {
          position: "bottom",
          duration: 1500,
        });
        this.newMessage = "";
        return;
      }

      if (this.code2fa_modal === "") {
        this.$toast.show(`Please enter a code`, { position: "bottom", duration: 1500 });
      } else if (this.newMessage.trim() === "") {
        this.$toast.show(`Please enter a message`, {
          position: "bottom",
          duration: 1500,
        });
      } else if (this.newMessage.length > 666) {
        // Adjust the maximum message length as needed
        this.$toast.show(`Message is too long (maximum 666 characters)`, {
          position: "bottom",
          duration: 1500,
        });
      } else {
        // Push the new message to the messages array
        // this.messages.push();
        this.$socket.emit("newMessage", {
          text: this.newMessage,
          token: this.$cookies.get("token"),
        });
        // Clear the input field
        this.newMessage = "";
        this.scrollChatToBottom();
      }
    },
  },
};
</script>

<style scoped>
.base {
  margin: 0px;
  padding: 0px 5px;
  border: 1px;
  margin-left: 1px;
}
/* Add your CSS styles here */

.chat {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
}

.chat-messages {
  flex-grow: 1;
  overflow-y: scroll;
  background: #030503;
  margin: 7px;
}

.chat-message {
  display: flex;
  align-items: center;
  margin: 10px;
}

.profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 2%;
  background: rgb(44, 44, 44);
  border: 1px solid white;
  outline: 1px solid black;
  margin-right: 10px;
}

.username {
  font-weight: bold;
  margin-right: 5px;
}

.chat-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  background-color: #1d1f1d;
}

input[type="text"] {
  flex-grow: 1;
  margin-right: 10px;
}

.inputerrx {
  border: 1px solid #5b5b5b;
  border-radius: 5px;
  height: 30px;
  background: #060a06;
  display: flex;
  align-items: center;
  color: white;
  font-size: 14px;
  width: 210px;
  padding: 2px 10px;
  outline: none !important;
  font-family: "Gilroy";
  justify-content: center;
}
.inputerrx {
  height: 50px;
}

@media only screen and (max-width: 600px) {
  .chatboxe {
    display: none;
  }
  .inputerrx {
    width: 100%;
  }
  button {
    width: 100px;
  }

  .btn_holder_mbd {
    position: fixed;
    bottom: 0px;
    z-index: 9999999999;
    width: 100%;
  }
}

button {
  background-color: #060a06;
  color: #c0c0c0;
  border: none;
  cursor: pointer;
  margin: 0px;
  padding: 3px 2px;
  height: 30px;
  font-size: 5px;
}
.inlineMSG {
  cursor: pointer;
  font-size: 16px !important;
  font-family: "Gilroy";
  color: #ffffff;
  text-shadow: 0px 0px 10px black;
  overflow: hidden;
  white-space: nowrap;
  overflow-wrap: break-word;
  display: inline-flex;
}

.online-users {
  text-align: center;
  padding: 10px;
  background-color: #1d1f1d;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
}
</style>
