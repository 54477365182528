import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Toaster from "@meforma/vue-toaster"
import VueSocketIO from 'vue-3-socket.io'
import VueCookies from 'vue3-cookies'
import VueCryptojs from 'vue-cryptojs'
import VueClipboard from 'vue3-clipboard'
import VueApexCharts from "vue3-apexcharts";
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import VueNumber from 'vue-number-animation'
import timeago from 'vue-timeago3'

import Config from './config'; 

const timeagoOptions = {
    converterOptions: {
        includeSeconds: true,
    }
  }

createApp(App).use(new VueSocketIO({
    connection: Config.connection,
    withCredentials: false,
})).use(Toaster).use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
  }).use(VueCookies, {
    expireTimes: "7000d",
}).use(router).use(VueCryptojs).use(VueNumber).use(timeago, timeagoOptions)
.use(FloatingVue).use(VueApexCharts).mount('#app')


