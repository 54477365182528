<template>
  <div class="base" :class="class_lvl">
    {{ level }}
  </div>
</template>

<script>

export default {
    name: 'LevelBadgeComponent',
  data() {

    return {

        dollar: 1,
        level: 0,
        class_lvl: 'level_boxz',
        xp: 0,
    

    }

  }, watch: {

    total_wager() {
        this.setclass()
    }


},  methods: {
    setclass() {
        this.xp = this.total_wager * 150
        this.level = Math.trunc((0.04 * Math.sqrt(this.xp*50)))

        if (this.offset) {
            this.level += this.offset
        }

        if (this.level > 0 && this.level < 20) {
            this.class_lvl = 'level_boxz'
        } else if (this.level >= 10 && this.level < 40) {
            this.class_lvl = 'level_box2'
        } else if (this.level == 0 ) {
            this.class_lvl = 'level_boxz'
            this.level = 1
        } else if (this.level >= 20 && this.level < 60) {
            this.class_lvl = 'level_box3'
        } else if (this.level >= 30 && this.level < 80) {
            this.class_lvl = 'level_box26'
        } else if (this.level >= 40 && this.level < 100) {
            this.class_lvl = 'level_box11'
        } 
        else if (this.level >= 50 && this.level < 120) {
            this.class_lvl = 'level_box6'
        } 
        else if (this.level >= 60 && this.level < 140) {
            this.class_lvl = 'level_box7'
        } 
        else if (this.level >= 70 && this.level < 160) {
            this.class_lvl = 'level_box8'
        } 
        else if (this.level >= 80 && this.level < 180) {
            this.class_lvl = 'level_box9'
        } 
        else if (this.level >= 90 && this.level < 200) {
            this.class_lvl = 'level_box10'
        } 
        else if (this.level >= 100 && this.level < 220) {
            this.class_lvl = 'level_box5'
        } 
        else if (this.level >= 110 && this.level < 240) {
            this.class_lvl = 'level_box12'
        } 
        else if (this.level >= 120 && this.level < 260) {
            this.class_lvl = 'level_box31'
        } 
        else if (this.level >= 130 && this.level < 280) {
            this.class_lvl = 'level_box14'
        } 
        else if (this.level >= 140 && this.level < 300) {
            this.class_lvl = 'level_box15'
        } 
        else if (this.level >= 150 && this.level < 320) {
            this.class_lvl = 'level_box16'
        } 
        else if (this.level >= 160 && this.level < 340) {
            this.class_lvl = 'level_box17'
        } 
        else if (this.level >= 170 && this.level < 360) {
            this.class_lvl = 'level_box18'
        } 
        else if (this.level >= 180 && this.level < 380) {
            this.class_lvl = 'level_box19'
        } 
        else if (this.level >= 190 && this.level < 400) {
            this.class_lvl = 'level_box20'
        } 
        else if (this.level >= 200 && this.level < 420) {
            this.class_lvl = 'level_box21'
        } 
        else if (this.level >= 210 && this.level < 220*2) {
            this.class_lvl = 'level_box22'
        } 
        else if (this.level >= 220 && this.level < 230*2 ) {
            this.class_lvl = 'level_box23'
        } 
        else if (this.level >= 230  && this.level < 240*2) {
            this.class_lvl = 'level_box24'
        } 
        else if (this.level >= 240 && this.level < 250*2) {
            this.class_lvl = 'level_box25'
        } 
        else if (this.level >= 250 && this.level < 260*2) {
            this.class_lvl = 'level_box4'
        } 
        else if (this.level >= 260 && this.level < 270*2) {
            this.class_lvl = 'level_box27'
        } 
        else if (this.level >= 270 && this.level < 280*2 ) {
            this.class_lvl = 'level_box28'
        } 
        else if (this.level >= 280  && this.level < 290*2) {
            this.class_lvl = 'level_box29'
        } 
        else if (this.level >= 290 && this.level < 300*2 ) {
            this.class_lvl = 'level_box30'
        } 
        else if (this.level >= 300  && this.level < 310*2) {
            this.class_lvl = 'level_box13'
        } else {
             this.class_lvl = 'level_box13'
        }
    }
},
    
    props: {
        total_wager: Number,
        offset: Number

    },  
mounted() {

 this.setclass()




        // <div class="level_box25">240</div>  
        // <div class="level_box4">250</div>
        // <div class="level_box27">260</div>  
        // <div class="level_box28">270</div>
        // <div class="level_box29">280</div>  
        // <div class="level_box30">290</div> 
        // <div class="level_box13">300</div>

    }
}

   


</script>

<style scoped> 
.base {
    display: inline;
    padding: 6px 21px;
    margin-left: 20px;
    font-size: 13px;
    font-family: monospace;
}

.level_box28 {
    background-image: linear-gradient(30deg, #352341, #13dd26);
    color: #000000;
    border: 2px solid #936767;
}
.level_box29 {
    background-image: linear-gradient(to left, #f2e0ff, #bf3636);
    color: #470000;
    border: 2px solid #ff0000;
   } 

   .level_box30 {
    background-image: linear-gradient(to left, #000000, #222222);
    color: #20d8ff;
    border: 2px solid #00f5ff;
    display: inline;
   } 

   .level_box31 {
    background-image: linear-gradient(to left, #e2ff0052, #ffc20075);
    color: #ffec34;
    border: 2px solid #ceff00;
   } 
.level_box27 {
    background-image: linear-gradient(to left, #9800ff, #bf3636);
    color: #000000;
    border: 2px solid #ff0000;
   } 
.level_box26 {
    background-image: linear-gradient(to left, #454545, #170909);
    color: #a9a9a9;
    border: 2px solid #e83d3d;
   } 
.level_box18 {
    background-image: linear-gradient(to left, #ed4bcf, #4640e7);
    color: #000000;
    border: 2px solid #ffffff;
}
.level_box17 {
    background-image: linear-gradient(#2fb8bf, #2496c1);
    color: #000000;
    border: 2px solid #22ff6a;

}
.level_box19 {
    background-image: linear-gradient(#740000, #090908);
    color: #ffffff;
    border: 2px solid #ffffff;
}
.level_box20 {
    background-image: linear-gradient(166deg,#d53131, #090908);
    color: #ffffff;
    border: 2px solid #ffffff;
}
.level_box21 {
    background-image: linear-gradient(166deg,#ff3232, #ff6000);
    color: #eeff70;
    border: 2px solid #ffe500;
}
.level_box22 {
    background-image: linear-gradient(166deg,#000000, #ff6000);
    color: #ffffff;
    border: 2px solid #000000;
}
.level_box23 {
    background-image: linear-gradient(166deg,#ff0000, #000000);
    color: #ffa6a6;
    border: 2px solid #ff4444;
}
.level_box24 {
    background-image: linear-gradient(279deg,#b5b5b5, #ffbe96);
    color: #000000;
    border: 2px solid #5e5e5e;
}
.level_box25 {
    background-image: linear-gradient(279deg,#975555, #ffbe96);
    color: #000000;
    border: 2px solid #5a1a1a;
}
 .level_box16  {
    background-image: linear-gradient(to left, #00bfff, #3d8191);
    color: #1016f4;
    border: 2px solid #0c6a7c;
 }
.level_box14 {
    background-image: linear-gradient(to left, #ff0000, #ff7272);
    color: #000000;
    border: 2px solid #fa380c;
}
.level_box15 {
    background-image: linear-gradient(to left, #ffd100, #c18e0a);
    color: #fff196;
    border: 2px solid #000000;
}
.level_boxz {
    background: #9c9c9c;
    font-family: monospace;
    color: #000000;
}
.level_box2 {
    background: #686868;
}
.level_box3 {
    background: #686868;
    color: #333333;
}
.level_box4 {
    background: #883267;
    color: #bc9191;
}
.level_box5 {
    background: #7d205a;
    color: #a66e91;
}
.level_box6 {
    background: #663399;
    color: #b99aad;
}
.level_box7 {
    background: #3cbb96;
    color: #2b6633;
}
.level_box8 {
    background: #6db647;
    color: #2b6633;
}.level_box9 {
    background: #4f9c27;
    color: #51da64;
    border: 2px solid #6db647;
}
.level_box10 {
    background: #4850ad;
    color: #1d7de1;
    border: 2px solid #1427ff;

}
.level_box11 {
    background: #3c3c3c;
    color: #ffe500;
    border: 2px solid #ffe500;

}
.level_box12 {
background-image: linear-gradient(to left, #ff000000, #5e5e5e);
    color: #ffe500;
    border: 2px solid #ffe600;

}

.level_box13 {
    background-image: linear-gradient(red, yellow);
    color: #ffe500;
    border: 2px solid #ffe600;

}


.home {
    max-width: 1280px;
    margin: auto;
    width: 80%;
    margin-bottom: 50px;
}
.red_vox {
    border-radius: 0px;
}
.level_box {
    background: #4b4b4b;
    border: 2px solid grey;
    color: #ffffff;
}
.oe {
background: #2d2d2d;
    border: 1px solid #686868;
        font-family: monospace;
}
</style>
